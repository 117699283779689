@page {
  size: 100mm 50mm;
  margin: 1px;
  font-family: Arial, sans-serif;
}
.label {
  width: 100mm;
  height: 49mm;
  font-size: 13px;
  font-weight: 400;
  font-style: none;
}
br,
ul,
p {
  padding: 0px;
  margin: 0px;
}
.label_header {
  list-style-type: none;
  padding: 0px;
  display: flex;
  align-items: center;
}
.label_header li {
  display: inline-block;
  padding-right: 5px;
}
.label_header li:last {
  padding-right: 10px;
}
p,
b {
}
address > p {
  padding: 1px 0px 1px 0px;
}
.logo img {
  width: 11mm;
  height: 8mm;
}
.bold {
  font-weight: 800;
  font-style: none;
}
.qrLogo {
  display: block;
  content-align: "text-center";
  padding: 0px;
  position: fixed;
}
.qrLogo img,
.qrLogo canvas {
  width: 25mm !important;
  height: 25mm !important;
}
.qrLogo p {
  text-align: center;
  margin-top: -0px;
}
.right {
  float: right;
  position: relative;
  top: -160px;
  right: 0px;
}
.label_details {
  list-style-type: none;
  padding: 3px;
  align-items: center;
}
.label_details li {
  padding: 1px 0px 1px 0px;
}
footer {
  bottom: 0px;
  left: 0px;
}
